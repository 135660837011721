import { jsx, useTheme } from '@emotion/react'
import React, { FC, useState } from 'react'
import { MdSearch } from 'react-icons/md'

import { ButtonStyled, LabelStyled, LinkStyled } from './styles'

type TextInputProps = {
    type?: 'submit' | 'reset' | 'button' | undefined
    color?: 'primary' | 'secondary' | 'default'
    onClick?: () => void
    fullWidth?: boolean
    href?: string
    margin?: 'no-margin' | 'large' | 'normal' | 'small'
    className?: string
    disabled?: boolean
}

const Button: FC<TextInputProps> = ({
    children,
    className,
    onClick,
    fullWidth = false,
    href,
    type = 'button',
    color = 'default',
    margin = 'normal',
    disabled = false,
}) => {
    return (
        <>
            {href ? (
                <LinkStyled className={className} color={color} fullWidth={fullWidth} to={href}>
                    <LabelStyled color={color}>{children}</LabelStyled>
                </LinkStyled>
            ) : (
                <ButtonStyled
                    type={type}
                    className={className}
                    fullWidth={fullWidth}
                    padding={margin}
                    disabled={disabled}
                    color={color}
                    onClick={(event) => {
                        // event.preventDefault()
                        onClick?.()
                    }}
                >
                    <LabelStyled color={color}>{children}</LabelStyled>
                </ButtonStyled>
            )}
        </>
    )
}

export default Button
