import styled from '@emotion/styled'
import { PageProps, graphql } from 'gatsby'
import * as React from 'react'

import Container from '../components/Container'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Button from '../components/common/Button'
import { useIntlLabel } from '../intl'
import { NotFoundPageQuery } from '../types'

export const TitleStyled = styled.h1((props) => {
    return {
        fontFamily: props.theme.fonts.default,
        color: props.theme.sections.notFound.foreground,
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '48px',
        lineHeight: '50px',
        marginBottom: props.theme.spacing(3),
    }
})

export const SubTitleStyled = styled.p((props) => {
    return {
        fontFamily: props.theme.fonts.default,
        color: props.theme.sections.notFound.foreground,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '23px',
        marginBottom: props.theme.spacing(3),
    }
})

export const SectionStyled = styled.section((props) => {
    return {
        backgroundColor: props.theme.sections.notFound.background,
        padding: props.theme.spacing(11, 0),
        margin: props.theme.spacing(11, 0),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [props.theme.breakpoints.down(props.theme.breakpoints.smartphone)]: {
            padding: props.theme.spacing(6, 3),
            margin: props.theme.spacing(3, 0),
        },
    }
})

interface NotFoundPageProps extends PageProps {
    data: NotFoundPageQuery
}

const NotFoundPage: React.FC<NotFoundPageProps> = ({ data }) => {
    const getIntlLabel = useIntlLabel()

    return (
        <Layout>
            <SEO title={getIntlLabel(data.strapiNotFoundSection, 'sectionTitle')} />
            <Container>
                <SectionStyled>
                    <TitleStyled>
                        {getIntlLabel(data.strapiNotFoundSection, 'sectionTitle')}
                    </TitleStyled>
                    <SubTitleStyled>
                        {getIntlLabel(data.strapiNotFoundSection, 'sectionSubTitle')}
                    </SubTitleStyled>
                    <Button href={`/`} type="button" color={'primary'}>
                        {getIntlLabel(data.strapiNotFoundSection, 'actionButtonLabel')}
                    </Button>
                </SectionStyled>
            </Container>
        </Layout>
    )
}

export const query = graphql`
    query NotFoundPage {
        strapiNotFoundSection {
            sectionSubTitle
            sectionTitle
            actionButtonLabel
        }
    }
`

export default NotFoundPage
